export const workspaceFilters = {
  mixed: [
    { name: "covid", value: "COVID-19" },
    { name: "comments", value: "Comments" },
    { name: "nps", value: "NPS Score" },
    { name: "rating", value: "CSAT Score" },
    { name: "accomplish", value: "Task Accomplished" },
    { name: "ca_number", value: "CA #" },
    { name: "previous_url", value: "Previous URL" },
    { name: "ip_address", value: "Ip Address" },
    { name: "performance_id", value: "Dynatrace ID" },
    { name: "session_id", value: "Session ID" },
    { name: "analytics_id", value: "Analytics ID" },
    { name: "device", value: "Device" },
    { name: "mobile_os", value: "Mobile OS" },
    { name: "survey_trigger", value: "Trigger Event" },
    { name: "non_comments", value: "Show non-comments" },
  ],
  web: [
    { name: "covid", value: "COVID-19" },
    { name: "comments", value: "Comments" },
    { name: "nps", value: "NPS Score" },
    { name: "rating", value: "CSAT Score" },
    { name: "accomplish", value: "Task Accomplished" },
    { name: "ca_number", value: "CA #" },
    { name: "url", value: "Current URL" },
    { name: "previous_url", value: "Previous URL" },
    { name: "ip_address", value: "Ip Address" },
    { name: "performance_id", value: "Dynatrace ID" },
    { name: "session_id", value: "Content Square" },
    { name: "analytics_id", value: "Analytics ID" },
    { name: "device", value: "Device" },
    { name: "survey_trigger", value: "Trigger Event" },
    { name: "non_comments", value: "Show non-comments" },
  ],
  'de-web': [
    { name: "covid", value: "COVID-19" },
    { name: "comments", value: "Comments" },
    { name: "nps", value: "NPS Score" },
    { name: "rating", value: "CSAT Score" },
    { name: "accomplish", value: "Task Accomplished" },
    { name: "ca_number", value: "CA #" },
    { name: "url", value: "Current URL" },
    { name: "previous_url", value: "Previous URL" },
    { name: "ip_address", value: "Ip Address" },
    { name: "performance_id", value: "Dynatrace ID" },
    { name: "session_id", value: "Content Square" },
    { name: "analytics_id", value: "Analytics ID" },
    { name: "device", value: "Device" },
    { name: "survey_trigger", value: "Trigger Event" },
    { name: "non_comments", value: "Show non-comments" },
  ],
  app: [
    { name: "covid", value: "COVID-19" },
    { name: "comments", value: "Comments" },
    { name: "nps", value: "NPS Score" },
    { name: "rating", value: "CSAT Score" },
    { name: "accomplish", value: "Task Accomplished" },
    { name: "ca_number", value: "CA #" },
    { name: "previous_url", value: "Previous URL" },
    { name: "ip_address", value: "Ip Address" },
    { name: "performance_id", value: "Dynatrace ID" },
    { name: "session_id", value: "Session ID" },
    { name: "analytics_id", value: "Analytics ID" },
    { name: "device", value: "Device" },
    { name: "mobile_os", value: "Mobile OS" },
    { name: "survey_trigger", value: "Trigger Event" },
    { name: "non_comments", value: "Show non-comments" },
  ],
  sf: [
    { name: "comments", value: "Comments" },
    { name: "helpful", value: "Helpful" },
    { name: "ca_number", value: "CA #" },
    { name: "page", value: "Page" },
    { name: "previous_url", value: "Previous URL" },
    { name: "ip_address", value: "Ip Address" },
    { name: "performance_id", value: "Dynatrace ID" },
    { name: "session_id", value: "Session ID" },
    { name: "analytics_id", value: "Analytics ID" },
    { name: "device", value: "Device" },
    { name: "non_comments", value: "Show non-comments" },
  ],
};
